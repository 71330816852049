<template>
	<header class='nav' :class="{'show-contact' : showContact, 'nav-mob':mobileNav,'active' : isOpen}">
		<div id="title">
			<h1 >Niels Visser</h1>
			<p>Freelance producer — TV & Online Commercials</p>
		</div>
		<hamburger id="mob-menu" v-if="mobileNav" @click.native="toggleNav()" :isOpen="isOpen"></hamburger>
		<transition name="fade">
			<ul v-if="showNav" 
			:class="{ 'mobile-nav': mobileNav }">
			<li v-if="mobileNav && hasOptions" class="desc">categorie</li>
			<li v-if="hasOptions" @click="select('all')" :class="{'current' : !selectedOption}" ref="all">All</li>

			<li v-if="hasOptions" v-for='option in options' 
			ref="options" 
			@click.self='select(option)' 
			:class="{ 'current' : option === selectedOption  }" 
			>{{ option.titel }}</li>
			<li id='contact' v-show='!mobileNav' :class="{'current' : showContact }" @click='toggleContact'>Contact</li>

			<li v-if="mobileNav" class="desc">contact</li>
			<li class='mob-nav' v-show='isOpen'><a href="mailto:contact@niels-visser.nl">contact@niels-visser.nl</a></li>
			<li class='mob-nav' v-show='isOpen'><a href="tel:+31622276521">06 222 765 21</a></li>

			<div v-if="!mobileNav" class="underline" ref="underline" :style='underline'></div>
		</ul>
	</transition>
	<aside id="contactInfo" v-if='!mobileNav'>
		<p class="itemInfoText">
			<a href="mailto:contact@niels-visser.nl">E-mail <strong>contact@niels-visser.nl</strong></a><br>
			<a href="tel:+31622276521">Telefoon <strong>06 222 765 21</strong></a></p>
		</aside>
	</header>
</template>

<script>
	import Hamburger from './Hamburger.vue';

	export default
	{
		data()
		{
			return {
				selectedOption: '',
				underline:{ left: 0, width: "0px"},
				isOpen: false,
			}
		},
		mounted() {
			if(!this.mobileNav) {
				this.underline.left = this.$refs.all.offsetLeft + 'px';
				this.underline.width = this.$refs.all.clientWidth + 'px';	
			}
		},
		computed: {
			windowWidth() {
				return this.$store.state.windowWidth;
			},
			mobileNav() {
				return (this.windowWidth <= 900);
			},
			showNav() {
				return (this.mobileNav && this.isOpen)||(!this.mobileNav);
			},
			hasOptions() {
				return this.options > 1;
			}
		},
		watch: {
			mobileNav(val) {
				if(!val)
				{
					this.isOpen = false;

					this.$nextTick(() => {
						let index = this.options.indexOf(this.selectedOption);

						if(index >=0) {
							this.moveUnderline(this.$refs.options[index])
						} else {
							this.moveUnderline(this.$refs.all);
						}

					}
					);
				}
			}
		},
		methods:
		{
			select(option) {
				this.hideNav();
				if(option === 'all')
				{
					this.selectedOption = '';	
					this.$emit('filter','');
					this.moveUnderline(this.$refs.all);
				} else {
					this.selectedOption = option;	
					this.$emit('filter',option.id);

					let index = this.options.indexOf(option);
					this.moveUnderline(this.$refs.options[index]);
				}
			},
			moveUnderline(el) {
				this.underline.left = el.offsetLeft + 'px';
				this.underline.width = el.clientWidth + 'px';	
			},
			toggleNav() {
				this.isOpen = !this.isOpen;
			},
			hideNav() {
				this.isOpen = false;
			},
			toggleContact() {
				this.$emit('toggleContact')
			}
		},
		props:
		['options','showContact'],
		components:
		{'hamburger':Hamburger}
	}
</script>
<style lang="scss" scoped>
	.nav.show-contact {
		height: 190px;
	}
	.nav {
		position: fixed;
		width:100%;
		height: 95px;
		background-color: black;
		font-family: EFR;
		top: 0;
		z-index: 1000;
		transition: height 0.5s ease-in-out;
		display: flex;
		overflow: hidden;
		justify-content: space-between;
		padding: 0px;
		box-sizing: border-box;
		align-items: center;
		flex-wrap: wrap;
		transition: height .5s ease-in-out;
		box-shadow: 0px 0px 63px 0px rgba(0,0,0,1);
		ul {
			display: flex;
			align-items: center;
			float: right;
			position: relative;
			text-align: right;
			list-style: none;
			margin: 0px;
			padding: 0px;

			li {
				cursor: pointer;
				text-decoration: none;
				display: inline;
				font-size:	2.5em;
				margin: 0 15px 10px;
				position: relative;
				&:not(:first-child):not(#contact):not(.desc):before
				{
					position: absolute;
					content: '/';
					left: -20px;
				}
			}


			&.mobile-nav {
				height: 100vh;
				background: black;
				position: fixed;
				width: 100vw;
				top: 0px;
				left: 0px;
				z-index: -1;
				flex-direction: column;
				padding-top: 95px;
				padding-bottom: 95px;
				justify-content: space-around;
				box-sizing: border-box;

				li.current:after {
					content: "";
					position: absolute;
					width: 100%;
					height: 9px;
					left: 0;
					background-color: #fff;
					bottom: -10px;

				}
				li.desc {
					text-transform: uppercase;
					font-size: 14px;

					&:after
					{
						content: '';
						position: absolute;
						width: 50vw;
						left: calc(100% + 8px);
						height: 8px;
						top: 6px;
						background: white;
					}
					&:before
					{
						content: '';
						position: absolute;
						width: 50vw;
						right: calc(100% + 8px);
						height: 8px;
						top: 6px;
						background: white;						
					}
				}

				li:not(:first-child):not(#contact):not(.desc):before
				{
					position: absolute;
					content: none;
					width: 100%;
					height: 8px;
				}
			}
		}
		&.nav-mob {
			overflow: visible;

			&.active {
				/*height: 100vh;*/
			}
			
		}

		#mob-menu
		{
			position: fixed;
			right: 20px;
			top: 20px;
			width: 40px;
			height: 40px;
		}

	}
	#title{
		font-size: 37px;
		height: 95px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 20px;
	}
	#title>h1 
	{
		font-size: 33px;
		line-height: 1.3;
		margin: 0px;
	}
	#title>p
	{
		margin: 0px 0px 5px;
		font-family: EFR;
		font-size: 13px;
	}

	.underline
	{
		position: absolute;
		bottom: 0px;
		width: 10px;
		height: 9px;
		background-color: white;
		transition: all .2s ease-in-out;
	}
	#contact
	{
		margin-left: 40px;
		margin-right: 20px;
		position: relative;

		&.current:before
		{
			width:100%;
		}

		&:before
		{
			content: "";
			position: absolute;
			width: 0;
			height: 9px;
			background-color: #fff;
			bottom: -10px;
			-webkit-transition: width .2s ease-in-out;
			transition: width .2s ease-in-out;
		}
	}

	#contactInfo
	{
		height: 95px;
		width: 100%;
		left: 10px;
		pointer-events: auto;
		background: black;


		p {
			margin: 0 20px;
			border-left: 9px solid white;
			padding-left: 10px;
			position: relative;
			height: auto;
			width: 100%;
			font-family: EFR;
			font-size: 15px;
			top: 50%;
			transform: translateY(-50%);

			a {
				text-decoration: none;
				color: inherit;
			}
		}
	}
	#contactInfo>p>a
	{
		line-height: 1.2em;
		padding-left: 0;
		transition: padding 0.1s ease-in-out;
	}
	#contactInfo>p>a:hover
	{
		padding-left: 5px;
	}
	.mob-nav  a
	{
		color: white;
	}
</style>