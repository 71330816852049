<template>
	<video ref="video" autoplay :src="src" controls></video>
</template>
<script>
	export default
	{
		mounted() {
			this.$refs.video.onloadeddata = () => {
				this.resize();
			};
			window.addEventListener('resize', this.resize);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize);
		},
		methods:
		{
			resize() {
				let el = this.$refs.video;
				let height = el.clientHeight;

				this.$emit('videoLoaded',height);	
			}
		},
		props:
		['src']		
	}
</script>
<style>
	video
	{
		width: 100%;
		background-color: black;
		max-height: calc(100vh - 95px);
		height: auto;
		margin: 0;
		padding: 0;
	}
</style>