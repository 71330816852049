<template>
	<div>
		<nav-bar @filter='filter' :options='categories' :showContact='showContact' @toggleContact='toggleContact'></nav-bar>
		<div class="container" :class="{'show-contact' : showContact}">
			<transition-group name="project">
				<project v-for='project, index in filteredProjects' v-bind='project' :key="project.id" @click.native=expand(project) :isExpanded='project === selectedProject' @minimize='minimize'></project>
			</transition-group>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import Project from './components/Project';
	import Vuex from 'vuex';


	export default
	{
		data() {

			return {
				categories: {},
				projects: {},
				selectedProject: {},
				selectedCategory: '',
				timer: '',
				showContact: false
			}
		},

		computed: {

			filteredProjects() {
				this.selectedProject = '';
				this.showContact = false;

				if (this.selectedCategory) {
					return this.projects.filter((project)=>{
						return project.category_id == this.selectedCategory;
					});

				} else {
					return this.projects;
				}
			}

		},

		methods:
		{
			fetchData() {
				axios
				.get('/api/public')
				.then(response => {
					this.categories = response.data.categories;
					this.projects = response.data.projects;
				}, error => {
					console.log(error.data);
				});
			},

			filter(category) {
				this.selectedCategory = category;
			},

			expand(project) {

				this.selectedProject = project;
				this.scrollToProject(project);
				this.showContact = false;
			},

			scrollToProject(project) {
				(setTimeout(() => {
					let index = this.filteredProjects.indexOf(project);
					window.scroll({
						top: index*160,
						behavior: 'smooth'
					});
				}, 250));		
			},

			minimize() {
				this.selectedProject = '';
			},
			toggleContact() {
				this.showContact = !this.showContact;
			}

		},

		created() {
			this.fetchData();	
		},

		beforeDestroy() {
		},

		components: { Project }
	}

</script>

<style scoped>
	.project-enter { 
		height: 0px;
		opacity: 0;
	}
	.project-enter-to {
		opacity: 0;
	}
	.project-enter-active {
		transition: all .4s;
	}

	.project-leave-active {
		transition: all .4s;
	}
	.project-leave {
		opacity: 0;
	}
	.project-leave-to {
		height: 0px;
		opacity: 0;
	}

	.container{
		width:100%;
		height:	auto;
		margin-top: 95px;
		transition: margin 0.5s ease-in-out;
	}

	.show-contact.container {
		margin-top: 190px;
	}

</style> 


