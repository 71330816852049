<template>
	<div class='project'  :class="[{ 
	'expanded': isExpanded}]" :style= "[isExpanded ? {height: height} : {}]">
	<transition name='fade'>
		<img class='background' v-if="(!isExpanded)" :src="'uploads/'+afbeelding.filename" :srcset="
		srcset" sizes="100vw" >
	</transition>
	<transition name='fade'>
		<img v-if="(isExpanded && showInfo)" class="close-button" src="/general/kruis.svg" alt="" @click="minimize">
	</transition>
	<transition name='fade'>
		<div v-if="showInfo" class="project-info" v-html='omschrijving'></div>
	</transition>
	<video-player @videoLoaded='changeHeight' v-if='isExpanded' :src="'uploads/' + video.filename"></video-player>
</div>
</template>
<script>
	import VideoPlayer from './VideoPlayer'
	export default
	{
		data : function () {
			return {

				height: '',
				showInfo: true,
				timer: ''
			}
		},
		watch: {
			isExpanded(){
				if (this.isExpanded) {
					window.addEventListener('mousemove', this.startTimer, false);
					this.startTimer();
				} else {
					this.removeTimer();
				}
			}
		},
		computed: {
			srcset() {
				let filename = this.afbeelding.filename.replace(/\.[^.]+$/, '.jpg');
				return 'uploads/large/'+filename + ' 1800w, '+'uploads/medium/'+filename + ' 1200w, '+'uploads/small/'+filename + ' 600w, ';
			}
		},
		methods: {
			changeHeight(height) {
				this.height = height+'px';
			},

			startTimer() {
				this.showInfo = true;
				clearTimeout(this.timer);
				this.timer = setTimeout( () => {
					this.showInfo = false;
				}, 2000);	
			},

			removeTimer() {
				clearTimeout(this.timer);
				window.removeEventListener('mousemove', this.startTimer, false);
				this.showInfo = true;
			},

			minimize() {
				event.stopPropagation();
				this.$emit('minimize');	
			}
		},
		props:
		['afbeelding','omschrijving','isExpanded','video'],
		components:
		{ VideoPlayer }
	}
</script>
<style lang="scss">
	.project{
		cursor: pointer;
		position: relative;
		display: block;
		margin: 0px 10px 10px;
		overflow: hidden;
		transition: all 0.3s ease-in-out;
		left: 0px;
		height: 150px;
		margin-bottom: 10px;

		img.background
		{
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit:cover;
			top: 0;
			pointer-events:none;
		}
		@media (any-hover: hover) {
			&:not(.expanded):hover
			{
				height: 220px;
			}
		}
	}

	.close-button 
	{
		position: absolute;
		transition: all .4s ease-in-out;
		top: 10px;
		right: 10px;
		width: 30px;
		z-index: 1;
		cursor: pointer;

		&:hover
		{
			transform: rotate(90deg);
		}
	}

	.project-info{
		position: absolute;
		top: 0;
		left: 0;
		height:150px;
		z-index: 20;
		opacity: 100;
		pointer-events:none;
		transition-delay: 0.5s;
		transition: opacity  1s ease-in-out;

		& strong
		{
			font-family: EFB;
		}

		& > *
		{
			margin: 0 10px;
			border-left: 9px solid white;
			padding-left: 10px;
			position: relative;
			height: auto;
			width: 100%;
			font-family: EFR;
			font-size: 15px;
			top: 50%;
			transform: translateY(-50%);


		}
		&::before
		{
			content: '';
			position: absolute;
			width: 400px;
			max-width: calc(100vw - 20px);
			height: 100vh;
			background: linear-gradient(to right, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 100%)
		}
	}

	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to{
		opacity: 0;
	}
</style>