<template>
	<div>
		<transition name="hamburger">
			<div v-if="!isOpen" id="hamburger">
				<div></div>
				<div></div>
				<div></div>
			</div>
		</transition>

		<transition name="close">
			<div id="close-menu" v-if="isOpen">
				<div></div>
				<div></div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default 
	{
		data(){
			return {

			}
		},
		props:
		['isOpen']

	}
</script>

<style lang="scss" scoped>
	#close-menu
	{
		width: 100%;
		height: 100%;
		position: absolute;
		transform: rotate(45deg);
		transition: transform .3s ease-in-out;

		& > *
		{
			width: 100%;
			height: 5px;
			background: white;
			position: absolute;
			top: calc(50% - 2.5px);

			&:first-child
			{
				transform: rotate(90deg);
			}			
		}

		&:hover
		{
			transform: rotate(135deg);
			}

		}

		.close-enter{ 
			& > div {
				background: red;
				/*transform: scaleX(0) !important;*/
			}
		}
		.close-enter-active, .close-leave-active {
			& > div
			{
				transition: all .5s;
			}
			/*opacity: 1;*/
		}
		.close-leave-to {
			& > div {
				transform: scaleX(0) !important;


			}
		}
		#hamburger
		{
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			cursor: pointer;


			& > *
			{
				transform-origin: left;
				width: 100%;
				height: 5px;
				background: white;
				transition: transform .3s ease-in-out;

				&:nth-child(3)
				{
					transform: scaleX(0.5);
				}
			}
			&:hover > *:nth-child(3)
			{
				transform: scaleX(1);
			}
		}
		.hamburger-enter{ 
			& > div {
				transform: scaleX(0) !important;
			}
		}
		.hamburger-enter-active, .hamburger-leave-active {
			transition: all 4s;
		}
		.hamburger-leave-to {
			& > div {
				transform: scaleX(0) !important;
			}
		}
	</style>