window._ = require('lodash');
window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Vue from 'vue';
import Vuex from 'vuex'

import AppView from './AppView.vue';
import NavBar from './components/NavBar.vue';

Vue.component('app-view', AppView);
Vue.component('nav-bar', NavBar);

const store = require('./State').default;

const app = new Vue({
	el: '#app',
	data: {
	},
	created() {
		window.addEventListener('resize',()=> { this.$store.commit('setWindowWidth') });
	},
	store,
});

